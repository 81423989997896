<template>
  <q-input
    ref="baseInputRef"
    :class="['baseInput', { 'baseInput--borderless': borderless }]"
    dense
    outlined
    :borderless="borderless"
    :clearable="!disabled && clearable"
    :disable="disabled"
    :error="error"
    :error-message="errorMessage"
    :hint="hint"
    :min="min"
    :max="max"
    :value="value"
    :label="getLabel"
    :placeholder="placeholder"
    :rules="validators ? validators : rules"
    :type="inputType"
    :step="step"
    :debounce="debounce"
    @clear="$emit('update:value', '')"
    @input="$emit('update:value', $event)"
  >
    <template #append>
      <slot name="append">
        <BaseIcon v-if="input_icon" :icon="input_icon" :custom_style="{ cursor: 'pointer', height: '16px', stroke: '#8F8F8F', width: '16px' }" @click.native.stop="showPassword = !showPassword" />
      </slot>
    </template>
  </q-input>
</template>

<script>
// Base Components
import BaseIcon from '@base/BaseIcon.vue';

export default {
  name: 'BaseInput',
  components: {
    BaseIcon
  },
  model: {
    prop: 'value',
    event: 'update:value'
  },
  props: {
    /**
     * True if a borderless input is needed
     */
    borderless: { default: false, type: Boolean },
    /**
     * True if input should be clearable
     */
    clearable: { default: true, type: Boolean },
    /**
     * Set to true if input should be disabled
     */
    disabled: { default: false, type: Boolean },
    error: { default: false, type: Boolean },
    errorMessage: { default: '', type: String },
    /**
     * Possible input hint
     */
    hint: { default: '', type: String },
    /**
     * Use this prop if want to show some icon inside the input field
     */
    icon: { default: '', type: String },
    /**
     * Label of input field
     */
    label: { default: undefined, type: String },
    /**
     * Set to true if the field is mandatory
     */
    mandatory: { default: false, type: Boolean },
    /**
     * The maximum number of characters that can be entered
     */
    max: { default: Infinity, type: Number },
    /**
     * The maximum number of words that can be entered
     */
    maxWords: { default: Infinity, type: Number },
    /**
     * The minimum value for number inputs
     */
    min: { default: 0, type: Number },
    /**
     * Placeholder
     */
    placeholder: { default: '', type: String },
    /**
     * Set the step of the  input
     */
    step: { default: 1, type: Number },

    /**
     * Type of input (i.e.: password)
     */
    type: { default: 'text', type: String },
    /**
     * Value
     */
    value: { default: null, type: [Number, String] },
    /**
     * Prop used to pass custom validators array
     */
    validators: { type: Array },
    debounce: { type: Number }
  },
  data() {
    return {
      showPassword: false,
      rules: [
        value => ((!value || value.length === 0) && this.mandatory ? this.$t('FORM_VALIDATION.TEXT_REQUIRED') : null),
        value => (value && value.length < this.min ? this.$t('FORM_VALIDATION.TEXT_TOO_SHORT', { number: this.min }) : null),
        value => (value && value.length > this.max ? this.$t('FORM_VALIDATION.TEXT_TOO_LONG', { number: this.max }) : null),
        value => (value && value.trim().split(/\s+/).length > this.maxWords ? this.$t('FORM_VALIDATION.TEXT_TOO_LONG_WORDS', { number: this.maxWords }) : null)
      ]
    };
  },

  computed: {
    /**
     * Returning the label with * if its mandatory
     */
    getLabel() {
      if (this.mandatory) return `${this.label}*`;
      return this.label;
    },
    /**
     * Returns the input type based on the one passed in the prop.
     * If the prop value is 'password' is passed a check is added to show or not the password
     */
    inputType() {
      if (this.type === 'password' && this.showPassword) return 'text';

      return this.type;
    },
    /**
     * Returns the icon
     */
    input_icon() {
      if (this.type === 'password') {
        if (this.showPassword) return 'eye-off';

        return 'eye';
      }

      return this.icon;
    }
  }
};
</script>

<style lang="scss">
.baseInput {
  // This targets both q-input and q-textarea due to their shared base class structure
  &.q-input,
  &.q-textarea {
    .q-field__control {
      height: 45px !important;
      border-radius: 10px;
      color: var(--main-text-color) !important;
      font-size: 16px !important; // Ensure font size is at least 16px

      &::before {
        border: 1px solid var(--form-fields-border-color);
      }
    }

    &--borderless .q-field__control {
      &::after,
      &::before {
        border: unset !important;
      }
    }
  }

  .q-field__label {
    color: var(--secondary-text-color);
  }
  .q-field__focusable-action {
    display: none !important;
  }
}

// Specific styles for q-textarea if needed
.textarea {
  overflow: unset;
}

// Hides the scrollbar in a dense, labeled q-textarea, might be needed for q-input if it has a similar structure
.q-textarea.q-field--dense.q-field--labeled .q-field__control-container,
.q-input.q-field--dense.q-field--labeled .q-field__control-container {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
